<template>
  <div class="hk-content">
    <el-steps :active="active" process-status="current">
      <el-step :class="{'hk-current': active < 2}">
        <i slot="icon" class="iconfont icon-success" />
        <span slot="title">{{ $t('cart.productConfirm') }}</span>
      </el-step>
      <el-step :class="{'hk-current': active === 2}">
        <i slot="icon" class="iconfont icon-car" />
        <span slot="title">{{ $t('cart.address') }}</span>
      </el-step>
      <el-step>
        <i slot="icon" class="iconfont icon-confirm" />
        <span slot="title">{{ $t('cart.payment') }}</span>
      </el-step>
    </el-steps>
    <component
      :is="current"
      @next="next"
      @prev="prev"
    />
  </div>
</template>

<script>
import HkCartProduct from './product'
import HkCartAddress from './address'
import HkCartPayment from './payment'
export default {
  name: 'HkCart',
  data () {
    return {
      active: 0,
      pages: [
        HkCartProduct,
        HkCartAddress,
        HkCartPayment
      ]
    }
  },
  computed: {
    current () {
      return this.pages[this.active]
    }
  },
  methods: {
    next () {
      this.active < 2 && this.active++
    },
    prev () {
      this.active > 0 && this.active--
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/index';
</style>
