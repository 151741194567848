<template>
  <div class="hk-cart">
    <div class="hk-table">
      <!--- // product section --->
      <el-button @click="selectAllToggle" class="selectAll">
        <span>&nbsp;</span>
        <span v-if="selectAllStatus">{{ $t('common.selectAll')}}</span>
        <span v-if="!selectAllStatus">{{ $t('common.unselectAll') }}</span>
        <span>&nbsp;</span>
      </el-button>
      <el-table :data="productList" :empty-text="$t('common.noData')">
        <el-table-column width="55">
          <template slot-scope="{ row }">
            <el-checkbox
              :value="row.selected"
              v-model="row.selected"
              v-on:change="syncCart(row.product_id, row.num, row.selected)"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('cart.productName')"
          min-width="250"
          class-name="hk-table__product"
        >
          <template slot-scope="{ row }">
            <img :src="row.square_img" />
            <div class="hk-table__info">
              <h4>{{ row.category_name }}</h4>
              <h3>{{ row.name }}</h3>
              <p>{{ $t('common.size') }}：{{ row.size }}</p>
              <p>{{ $t('common.packing') }}：{{ row.package }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('cart.price')" min-width="100">
          <template slot-scope="{ row }">
            <p
              v-if="row.origin_price == row.actual_price"
              class="hk-table__price"
            >HK$ {{ row.origin_price }}</p>
            <p
              v-if="row.origin_price && row.origin_price != row.actual_price"
              class="hk-table__prime"
            >HK$ {{ row.origin_price }}</p>
            <p
              v-if="row.origin_price != row.actual_price"
              class="hk-table__price"
              :class="{'hk-table__price_red': row.actual_price}"
            >HK$ {{ row.actual_price }}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('cart.quantity')" min-width="110">
          <template slot-scope="{ row }">
            <el-input-number
              v-model="row.num"
              :min="0"
              :max="row.reserve"
              v-on:change="syncCart(row.product_id, row.num, row.selected)"
              controls-position="right"
            />
          </template>
        </el-table-column>
        <el-table-column :label="$t('cart.subtotal')" min-width="110">
          <template slot-scope="{ row }">
            <p class="hk-table__subtotal">HK$ {{ row.actual_total_price }}</p>
          </template>
        </el-table-column>
        <el-table-column width="60">
          <template slot-scope="{ row }">
            <i class="iconfont icon-close" @click="handleDelete(row.product_id)" />
          </template>
        </el-table-column>
      </el-table>

      <!--- // gift section --->
      <transition name="slide-left">
        <div v-show="presentShow && giftList.length" class="hk-present">
          <h4>{{ $t('cart.selectFreeProduct') }}</h4>
          <h4>{{ $t('cart.maxGiftQuantity', [giftMaxQuantity, maxGiftNum])}}</h4>
          <h4>{{ $t('cart.maxGiftPrice', [giftMaxPrice, maxGiftPrice])}}</h4>
          <ul class="hk-present__list">
            <li
              v-for="(item, index) of giftList"
              :key="index"
              class="hk-present__item"
              v-on:change="syncGift(item.id, item.num, item.selected)"
            >
              <img :src="item.square_img" />
              <h3>{{ item.name }}</h3>
              <p>{{ $t('common.size') }}：{{ item.size }}</p>
              <p>{{ $t('common.packing') }}：{{ item.package }}</p>
              <p>{{ $t('common.giftPrice') }}：{{ item.discount_price }}</p>
              <el-input-number
                v-model="item.num"
                :min="0"
                :max="giftMaxQuantity"
                controls-position="right"
                v-on:change="syncGift(item.id, item.num, item.selected)"
              />
              <el-checkbox v-model="item.selected" />
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <!--- // price section --->
    <div class="hk-total">
      <div class="hk-total__table">
        <el-table :data="productList" :empty-text="$t('common.noData')">
          <el-table-column :label="$t('cart.product')" class-name="hk-table_title">
            <template slot-scope="{ row }" v-if="row.selected">
              <span style="width: auto;">{{ row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('cart.quantity')" class-name="hk-table_title">
            <template slot-scope="{ row }" v-if="row.selected">
              <span>{{ row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
        <dl>
          <dt>{{ $t('cart.expensesSubtotal') }}</dt>
          <dd class="hk-total__subtotal">HK$ {{ checkoutCalculation.oriSubtotal }}</dd>
        </dl>
        <!-- <dl>
          <dt>{{ $t('cart.rebate') }}</dt>
          <dd>- HK$ {{ checkoutCalculation.refund }}</dd>
        </dl> -->
      </div>
      <dl class="hk-total__amount">
        <dt>{{ $t('cart.total') }}</dt>
        <dd>HK$ {{ checkoutCalculation.newSubtotal }}</dd>
      </dl>
      <div v-if="presentData.length" class="hk-total__table">
        <el-table :data="giftList" :empty-text="$t('common.noData')">
          <el-table-column :label="$t('cart.freeProduct')" class-name="hk-table_title">
            <template slot-scope="{ row }">
              <div v-if="row.selected == true">{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="{ row }">
              <span v-if="row.selected == true">{{ row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="hk-total__button">
        <el-button type="primary" @click="updateProductList">{{ $t('common.next') }}</el-button>
      </div>
    </div>

    <el-dialog class="hk-alert" :visible.sync="checkoutAlert">
      <div>
        <p v-if="quantityAlert">{{ $t('cart.quantityAlert', [giftMaxQuantity]) }}</p>
        <p v-if="priceAlert">{{ $t('cart.priceAlert', [giftMaxPrice]) }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" v-on:click="checkoutFalse">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="noProductAlert">
      <div>
        <p>{{ generalAlertMessage }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="noProductAlert = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HkCartProduct',
  data() {
    this.getProductList()
    // this.getPickupDetail()
    return {
      tempCheckbox: true,
      multipleSelection: [],
      productList: [],
      giftList: [],
      giftMaxQuantity: 0,
      giftMaxPrice: 0,
      checkoutCalculation: {
        oriSubtotal: 0,
        newSubtotal: 0,
        refund: 0,
      },
      presentShow: false,
      amountData: [
        {
          name: 'A2',
          value: 10,
        },
        {
          name: '19pcs/set',
          value: 20,
        },
      ],
      present: 10000,
      presentData: [
        {
          name: 'MDP/Q7',
          value: 5,
        },
        {
          name: 'W2',
          value: 5,
        },
      ],
      //selectAllStatus: true,
      handleSelectionChangeStatus: false,
      selectSum: 0,
      showGiftAmountAlert: false,
      quantityAlert: false,
      priceAlert: false,
      noProductAlert: false,
      selectProductCheck: 0,
      generalAlertMessage: '',
      checkoutStatus: false,
      checkedAll: true,
    }
  },
  computed: {
    tableData() {
      return []
    },
    presents() {
      return []
    },
    maxGiftNum() {
      let self = this
      self.quantityAlert = false
      let sum = 0
      let status = false
      this.giftList.forEach(function (item) {
        if (item.selected == true) {
          sum += item.num
        }
      })
      if (sum > this.giftMaxQuantity && self.checkoutStatus) {
        self.quantityAlert = true
        self.showGiftAmountAlert = true
      }
      return sum
      //self.selectSum = sum;
      //return this.selectSum
    },
    maxGiftPrice() {
      let self = this
      self.priceAlert = false
      let sum = 0
      let status = false
      self.giftList.forEach(function (item) {
        if (item.selected == true) {
          sum += item.num * item.discount_price
        }
      })
      if (sum > self.giftMaxPrice && self.checkoutStatus) {
        self.priceAlert = true
        self.showGiftAmountAlert = true
      }
      return sum
      //self.selectSum = sum;
      //return this.selectSum
    },
    checkoutAlert() {
      let state = this.checkoutStatus && this.showGiftAmountAlert
      return state
    },
    selectAllStatus() {
      let self = this
      let state = true
      let counter = 0
      self.productList.forEach(function (item) {
        if (item.selected == false) {
          counter++
        }
      })
      if (counter == 0) {
        state = false
      }
      return state
    },
  },
  mounted() {
    setTimeout(() => {
      this.presentShow = true
    }, 1000)
  },
  methods: {
    next() {
      this.$emit('next')
    },
    handleDelete(id) {
      const index = this.tableData.findIndex((item) => item.id === id)
      this.tableData.splice(index, 1)
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/remove?'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      let productId = this.$router.history.current.query.id
      formData = {
        product_id: id,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.getProductList()
          self.updateCart()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleSelectionChange(val, e) {
      this.multipleSelection = val
    },
    selectAll(val, e) {
      let self = this
      let selectCount = 0
      self.productList.forEach(function (item) {
        if (!item.selected) {
          item.selected = true
          self.syncCart(item.product_id, item.num, item.selected)
          selectCount++
        }
      })
      if (selectCount == 0) {
        self.productList.forEach(function (item) {
          item.selected = false
          self.syncCart(item.product_id, item.num, item.selected)
        })
      }
    },
    // get product list
    getProductList() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/shopping/products?lang=' +
        self.$store.state.config.language
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.productList = response.data.data.goods

          self.checkoutCalculation.oriSubtotal = 0
          self.productList.forEach(function (item) {
            if (item.selected == 'Y') {
              self.checkoutCalculation.oriSubtotal += item.actual_total_price
            }
          })
          self.checkoutCalculation.newSubtotal = response.data.data.sum
          self.checkoutCalculation.refund = response.data.data.use_back_money
          self.giftList = response.data.data.preferential.can_select_gifts
          self.selectTranslateRev()
          self.giftMaxQuantity = response.data.data.preferential.gifts_max_num
          self.giftMaxPrice = response.data.data.preferential.gifts_max_price
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    updateProductList() {
      let self = this
      if (
        self.maxGiftNum > self.giftMaxQuantity ||
        self.maxGiftPrice > self.giftMaxPrice
      ) {
        self.checkoutStatus = true
        return
      }

      if(self.giftMaxQuantity > 0 && self.maxGiftNum == 0) {
        self.generalAlertMessage = self.$t('cart.noGift')
        self.noProductAlert = true
        return
      }

      self.selectTranslate()

      self.productList.forEach(function (data) {
        self.updateProductHandler(data, 'product')
      })
      self.giftList.forEach(function (data) {
        self.updateProductHandler(data, 'gift')
      })
      if (self.selectProductCheck == 0) {
        self.generalAlertMessage = self.$t('cart.noProduct')
        self.noProductAlert = true
      } else {
        self.next()
      }
    },
    async updateProductHandler(data, type) {
      let self = this
      let postType = ''
      let product_id = Number
      if (type == 'product') {
        postType = 'update'
        product_id = data.product_id
      } else if (type == 'gift') {
        postType = 'updateGifts'
        product_id = data.id
      }
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/' + postType
      let formData = new FormData()
      formData = {
        product_id: product_id,
        num: data.num,
        selected: data.selected,
      }
      let res = await self.axios
        .post(apiCall, formData, header)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error)
        })
    },
    syncCart(productId, num, selected) {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/update'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      if (selected == true) {
        selected = 'Y'
      } else if (selected == false) {
        selected = 'N'
      }
      formData = {
        product_id: productId,
        num: num,
        selected: selected,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.productList = response.data.data.goods
          self.checkoutCalculation.oriSubtotal = 0
          self.productList.forEach(function (item) {
            if (item.selected == 'Y') {
              self.checkoutCalculation.oriSubtotal += item.actual_total_price
            }
          })
          self.checkoutCalculation.newSubtotal = response.data.data.sum
          self.checkoutCalculation.refund = response.data.data.use_back_money
          self.giftList = response.data.data.preferential.can_select_gifts
          self.selectTranslateRev()
          self.giftMaxQuantity = response.data.data.preferential.gifts_max_num
          self.giftMaxPrice = response.data.data.preferential.gifts_max_price
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    syncGift(productId, num, selected) {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/updateGifts'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      if (selected == true) {
        selected = 'Y'
      } else if (selected == false) {
        selected = 'N'
      }
      formData = {
        product_id: productId,
        num: num,
        selected: selected,
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              break
            case 2008:
              self.generalAlertMessage = self.$t('common.outOfStock')
              self.noProductAlert = true
              self.getProductList()
              break
            default:
              break
          }
          self.getProductList()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    selectTranslate() {
      let self = this
      self.selectProductCheck = 0
      self.productList.forEach(function (item) {
        if (item.selected == true) {
          item.selected = 'Y'
          self.selectProductCheck++
        } else if (item.selected == false) {
          item.selected = 'N'
        }
      })
      self.giftList.forEach(function (item) {
        if (item.selected == true) {
          item.selected = 'Y'
        } else if (item.selected == false) {
          item.selected = 'N'
        }
      })
    },
    selectTranslateRev() {
      this.productList.forEach(function (item) {
        if (item.selected == 'Y') {
          item.selected = true
        } else if (item.selected == 'N') {
          item.selected = false
        }
      })
      this.giftList.forEach(function (item) {
        if (item.selected == 'Y') {
          item.selected = true
        } else if (item.selected == 'N') {
          item.selected = false
        }
      })
    },
    updateCart() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios.get(apiCall, header).then(function (response) {
        let num = response.data.data.product_num
        self.$store.commit('updateCartNum', num)
      })
    },
    updateData(response) {},
    checkoutFalse() {
      this.checkoutStatus = false
      this.showGiftAmountAlert = false
    },
    selectAllToggle() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall__selectAll =
        rootApi +
        '/api/shopping/selectAll?lang=' +
        self.$store.state.config.language
      let apiCall__unselectAll =
        rootApi +
        '/api/shopping/cancelAll?lang=' +
        self.$store.state.config.language
      let apiCall = ''
      if (self.selectAllStatus == true) {
        apiCall = apiCall__selectAll
      } else if (self.selectAllStatus == false) {
        apiCall = apiCall__unselectAll
      }
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          self.getProductList()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getPickupDetail() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/pick_up_time/list?lang=en'
      let res = await this.axios
        .get(apiCall)
        .then(function (response) {
          let array1 = response.data.data
          console.log(response.data.data)

          let pickupSlot = []
          array1.forEach(function (item) {
            item.period =
              item.start_hour.toString() +
              ':00:00 -' +
              item.end_hour.toString() +
              ':00:00'
            item.dateUnix = Date.parse(item.date)
            pickupSlot.push(item)
          })
          let pickupDateMin = pickupSlot[0].dateUnix
          let pickupDateMax = pickupSlot[pickupSlot.length - 1].dateUnix
          let arr = [pickupDateMin, pickupDateMax]
          self.$store.commit('savePickupDate', arr)
          self.$store.commit('savePickupSlot', pickupSlot)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/product';
</style>
<style>
.selectAll {
  border-radius: 0px;
  background-color: #8ab0d0;
  color: #252525;
  border: none;
}
.selectAll:hover {
  border-radius: 0px;
  background-color: #8ab0d0;
  color: #252525;
  border: none;
}
.selectAll:focus {
  border-radius: 0px;
  background-color: #8ab0d0;
  color: #252525;
  border: none;
}
</style>
