var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hk-cart"},[_c('div',{staticClass:"hk-table"},[_c('el-button',{staticClass:"selectAll",on:{"click":_vm.selectAllToggle}},[_c('span',[_vm._v(" ")]),(_vm.selectAllStatus)?_c('span',[_vm._v(_vm._s(_vm.$t('common.selectAll')))]):_vm._e(),(!_vm.selectAllStatus)?_c('span',[_vm._v(_vm._s(_vm.$t('common.unselectAll')))]):_vm._e(),_c('span',[_vm._v(" ")])]),_c('el-table',{attrs:{"data":_vm.productList,"empty-text":_vm.$t('common.noData')}},[_c('el-table-column',{attrs:{"width":"55"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.selected},on:{"change":function($event){return _vm.syncCart(row.product_id, row.num, row.selected)}},model:{value:(row.selected),callback:function ($$v) {_vm.$set(row, "selected", $$v)},expression:"row.selected"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('cart.productName'),"min-width":"250","class-name":"hk-table__product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.square_img}}),_c('div',{staticClass:"hk-table__info"},[_c('h4',[_vm._v(_vm._s(row.category_name))]),_c('h3',[_vm._v(_vm._s(row.name))]),_c('p',[_vm._v(_vm._s(_vm.$t('common.size'))+"："+_vm._s(row.size))]),_c('p',[_vm._v(_vm._s(_vm.$t('common.packing'))+"："+_vm._s(row.package))])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('cart.price'),"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.origin_price == row.actual_price)?_c('p',{staticClass:"hk-table__price"},[_vm._v("HK$ "+_vm._s(row.origin_price))]):_vm._e(),(row.origin_price && row.origin_price != row.actual_price)?_c('p',{staticClass:"hk-table__prime"},[_vm._v("HK$ "+_vm._s(row.origin_price))]):_vm._e(),(row.origin_price != row.actual_price)?_c('p',{staticClass:"hk-table__price",class:{'hk-table__price_red': row.actual_price}},[_vm._v("HK$ "+_vm._s(row.actual_price))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('cart.quantity'),"min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"min":0,"max":row.reserve,"controls-position":"right"},on:{"change":function($event){return _vm.syncCart(row.product_id, row.num, row.selected)}},model:{value:(row.num),callback:function ($$v) {_vm.$set(row, "num", $$v)},expression:"row.num"}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('cart.subtotal'),"min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"hk-table__subtotal"},[_vm._v("HK$ "+_vm._s(row.actual_total_price))])]}}])}),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"iconfont icon-close",on:{"click":function($event){return _vm.handleDelete(row.product_id)}}})]}}])})],1),_c('transition',{attrs:{"name":"slide-left"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.presentShow && _vm.giftList.length),expression:"presentShow && giftList.length"}],staticClass:"hk-present"},[_c('h4',[_vm._v(_vm._s(_vm.$t('cart.selectFreeProduct')))]),_c('h4',[_vm._v(_vm._s(_vm.$t('cart.maxGiftQuantity', [_vm.giftMaxQuantity, _vm.maxGiftNum])))]),_c('h4',[_vm._v(_vm._s(_vm.$t('cart.maxGiftPrice', [_vm.giftMaxPrice, _vm.maxGiftPrice])))]),_c('ul',{staticClass:"hk-present__list"},_vm._l((_vm.giftList),function(item,index){return _c('li',{key:index,staticClass:"hk-present__item",on:{"change":function($event){return _vm.syncGift(item.id, item.num, item.selected)}}},[_c('img',{attrs:{"src":item.square_img}}),_c('h3',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(_vm.$t('common.size'))+"："+_vm._s(item.size))]),_c('p',[_vm._v(_vm._s(_vm.$t('common.packing'))+"："+_vm._s(item.package))]),_c('p',[_vm._v(_vm._s(_vm.$t('common.giftPrice'))+"："+_vm._s(item.discount_price))]),_c('el-input-number',{attrs:{"min":0,"max":_vm.giftMaxQuantity,"controls-position":"right"},on:{"change":function($event){return _vm.syncGift(item.id, item.num, item.selected)}},model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}}),_c('el-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),0)])])],1),_c('div',{staticClass:"hk-total"},[_c('div',{staticClass:"hk-total__table"},[_c('el-table',{attrs:{"data":_vm.productList,"empty-text":_vm.$t('common.noData')}},[_c('el-table-column',{attrs:{"label":_vm.$t('cart.product'),"class-name":"hk-table_title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.selected)?[_c('span',{staticStyle:{"width":"auto"}},[_vm._v(_vm._s(row.name))])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":_vm.$t('cart.quantity'),"class-name":"hk-table_title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.selected)?[_c('span',[_vm._v(_vm._s(row.num))])]:undefined}}],null,true)})],1),_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t('cart.expensesSubtotal')))]),_c('dd',{staticClass:"hk-total__subtotal"},[_vm._v("HK$ "+_vm._s(_vm.checkoutCalculation.oriSubtotal))])])],1),_c('dl',{staticClass:"hk-total__amount"},[_c('dt',[_vm._v(_vm._s(_vm.$t('cart.total')))]),_c('dd',[_vm._v("HK$ "+_vm._s(_vm.checkoutCalculation.newSubtotal))])]),(_vm.presentData.length)?_c('div',{staticClass:"hk-total__table"},[_c('el-table',{attrs:{"data":_vm.giftList,"empty-text":_vm.$t('common.noData')}},[_c('el-table-column',{attrs:{"label":_vm.$t('cart.freeProduct'),"class-name":"hk-table_title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.selected == true)?_c('div',[_vm._v(_vm._s(row.name))]):_vm._e()]}}],null,false,3551788592)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.selected == true)?_c('span',[_vm._v(_vm._s(row.num))]):_vm._e()]}}],null,false,2782062102)})],1)],1):_vm._e(),_c('div',{staticClass:"hk-total__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.updateProductList}},[_vm._v(_vm._s(_vm.$t('common.next')))])],1)]),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.checkoutAlert},on:{"update:visible":function($event){_vm.checkoutAlert=$event}}},[_c('div',[(_vm.quantityAlert)?_c('p',[_vm._v(_vm._s(_vm.$t('cart.quantityAlert', [_vm.giftMaxQuantity])))]):_vm._e(),(_vm.priceAlert)?_c('p',[_vm._v(_vm._s(_vm.$t('cart.priceAlert', [_vm.giftMaxPrice])))]):_vm._e()]),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.checkoutFalse}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)]),_c('el-dialog',{staticClass:"hk-alert",attrs:{"visible":_vm.noProductAlert},on:{"update:visible":function($event){_vm.noProductAlert=$event}}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.generalAlertMessage))])]),_c('div',{staticClass:"hk-alert__button"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.noProductAlert = false}}},[_vm._v(_vm._s(_vm.$t('common.confirm')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }