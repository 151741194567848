<template>
  <div class="hk-cart">
    <div class="hk-payment__wrapper">
      <div class="hk-payment">
        <h4 class="hk-payment__price">{{ $t('cart.paymentAmount') }}: <span>HK$ {{ total }}</span></h4>
        <p>{{ $t('cart.choosePayment') }}：</p>
        <ul class="hk-payment__type">
          <li>
            <el-radio v-model="type" :label="1" @change="payType(1)">{{ $t('cart.onlinePayment') }}</el-radio>
            <div class="hk-payment__info">
              <img src="~images/stripe.png">
              <p>{{ $t('cart.onlinePaymentDesc.1') }}</p>
              <form :action="url" method="POST">
                <stripe-checkout
                  :stripe-key="newPublicKey"
                  :product="myProduct"
                  :button="$t('cart.payment')"           
                  >
                </stripe-checkout>
              </form>
            </div>
          </li>
          <li>
            <el-radio v-model="type" :label="2" @change="payType(2)">{{ $t('cart.offlinePayment') }}</el-radio>
            <div class="hk-payment__info">
              <!-- <el-select v-model="offlinePayType" :button="$t('cart.payment')">
                <el-option label="選擇銀行入帳 Bank in deposit" value="bank_transfer" default/>
                <el-option label="郵寄支票 Cheque mailing" value="cheque_mailing" />
              </el-select> -->
              <p>{{ offlinePaymentLabel }}</p>
              <h5>{{ $t('cart.methods.1') }}:</h5>
              <table>
                <tbody>
                  <tr>
                    <td colspan="2">
                      轉數快 或 銀行入數/轉帳 - 銀行櫃台、自 動櫃員機、網上理財 或 轉數快
                      <br />FPS or Bank in deposit/transfer – bank counter, ATM, e-banking
                    </td>
                  </tr>
                  <tr>
                    <td>戶口名稱<br>Account Name</td>
                    <td>HONG KONG SECURE TRANSPORTATION SYSTEMS LTD</td>
                  </tr>
                  <tr>
                    <td>戶口號碼<br>Account Number</td>
                    <td>395-666100-001</td>
                  </tr>
                  <tr>
                    <td>銀行<br>Bank</td>
                    <td>HANG SENG BANK LIMITED</td>
                  </tr>
                </tbody>
              </table>
              <p class="hk-payment__description">為了完成付款，請於系統內上傳付款單的副本 (必須清楚並在上面寫上發票編號)。<br>
              </p>
              <p class="hk-payment__description">In order to complete the payment, please upload a copy of the payment slip via the system (must be clear and write the invoice number on it).
              </p>
              <!-- <h5>{{ $t('cart.methods.2') }}:</h5>
              <table>
                <tbody>
                  <tr>
                    <td colspan="2">郵寄支票 Cheque mailing</td>
                  </tr>
                  <tr>
                    <td>支票抬頭請寫<br>Make cheque payable to</td>
                    <td>HONG KONG SECURE TRANSPORTATION SYSTEMS LIMITED</td>
                  </tr>
                  <tr>
                    <td>郵寄地址<br>Mailing address</td>
                    <td>香港新界荃灣海盛路3號TML廣場18樓A室 - 財務部<br>Room A, 18/F, TML Plaza, 3 Hoi Shing Road, Tsuen Wan, N.T., Hong Kong - Finance Department</td>
                  </tr>
                  <tr>
                    <td>備註<br>Remark</td>
                    <td>請於支票背面寫上公司名稱及訂單編號<br>Please write the company name & invoice number at the back of the cheque</td>
                  </tr>
                </tbody>
              </table>
              <p class="hk-payment__description">為加快確認付款程序，請於郵寄支票當天以電郵 enquiry@hksts.com.hk 通知我們，請在主旨列明「郵寄支票」，並註明訂單編號。在電郵內請加入以下資料：</p>
              <p class="hk-payment__description">In order to handle the payment confirmation process effectively, please inform us by email at enquiry@hksts.com.hk with the subject of “Cheque Sending” and the invoice number. Please include the following information in your email:</p>
              <p class="hk-payment__request">客戶編號 Customer Number: {{ customerNum }}</p>
              <p class="hk-payment__request">訂單編號 Order number: {{ invoiceNum }}</p>
              <p class="hk-payment__request">金額（港幣）Amount (HKD): ${{ total }}</p>
              <p class="hk-payment__request">支票號碼 Cheque number: XXXXXXXX</p> -->
            </div>
          </li>
        </ul>
      </div>
      <div class="hk-payment__button">
        <!---<el-button @click="prev">{{ $t('common.prev') }}</el-button>--->
        <el-button v-show="type != 1" type="primary" :disabled="offlinePaymentToggle" @click="submit">{{ $t('common.finish') }}</el-button>
      </div>
    </div>

    <el-dialog
      class="hk-success-dialog"
      :visible.sync="showSuccessDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <i class="iconfont icon-success" />
      <div><h4>{{ $t('common.success') }}</h4></div>
      <p>{{ $t('cart.dialog.1') }}</p>
      <p>{{ $t('cart.dialog.2') }}</p>
      <a @click="resend" v-if="resendStatus">{{ $t('cart.dialog.3') }}</a><br/>
      <p v-if="!resendStatus">{{ $t('cart.dialog.4') }}</p>
      <a @click="goHome">{{ $t('common.back') }}</a>
    </el-dialog>

    <el-dialog
      class="hk-alert"
      :visible.sync="showAlertDialog"
    >
      <p>{{ alertMessage }}</p>
      <div class="hk-alert__button"><el-button type="primary" @click="showAlertDialog = false">{{ $t('common.confirm') }}</el-button></div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="uncompleteAlert">
      <div>
        <p>{{ $t('cart.uncompleteAlertMessage') }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="uncompleteAlert = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
    
  </div>
</template> 

<script>
//import HkPayment from '@/components/payment'
export default {
  name: 'HkCartPayment',
  components: {
  },
  data () {
    this.swipeGetPublicKey();
    this.getOrderPrice();
    let customerNum = localStorage.getItem('HKSTS__userNum');
    return {
      total: 0,
      type: 1,
      showSuccessDialog: false,
      customerNum: customerNum,
      invoiceNum: '',
      publishableKey: '',
      newPublicKey: 'pk_live_51Gx0zdH3hCX8A8cVD2FslyLShhml7PtFTfjqJZ8yOIgLd8JzL2y7RbDb8t6SfRYAk7AMRohPWfYmHBHIyl1OoZe900ODiS8QhX',
      myProduct: {
        name: 'HKSTS',
        description: '',
        amount: '',
        currency: "hkd",
        invoiceNum: '',
      },
      url: '',
      offlinePayType: 'bank_transfer',
      receiptImage: [],
      receiptList: [],
      alertMessage: '',
      showAlertDialog: false,
      resendStatus: true,
      offlinePaymentState: false,
      offlinePaymentLabel: '',
      uncompleteAlert: false
    }
  },
  created() {
      this.uncompleteAlert = true
  },
  computed: {
    computePublicKey () {
      return this.publishableKey
    },
    offlinePaymentToggle () {
      return this.offlinePaymentState
    }
  },
  methods: {
    submit () {
      if (this.type === 2) { // OFFLINE PAYMENT
        this.goOfflinePayment();
      } else if (this.type === 1) { // ONLINE PAYMENT
        this.swipeGetPublicKey();
      }
      //this.showSuccessDialog = true
    },
    resend () {
      let self = this;
      self.resendStatus = false;
      let invoice = self.$store.state.config.invoiceNum
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/resendInvoice?order_sn=' + invoice
      this.axios.get(apiCall, header)
      .then(function(response) {
        let code = response.data.data;
        switch(code) {
          case 0:
            break
        }
      })
      .catch(function(error) {
        console.log(error);
      })
    },
    prev () {
      this.$emit('prev')
    },
    goHome () {
      this.$router.replace({
        name: 'home'
      })
    },
    getOrderPrice () {
      let self = this;
      let invoice = self.$store.state.config.invoiceNum
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/orderPrice?order_sn=' + invoice
      this.axios.get(apiCall, header)
      .then(function(response) {
        self.total = response.data.data.price;
        self.myProduct.amount = ( response.data.data.price * 100 ).toString();
        self.myProduct.invoiceNum = invoice;
        self.invoiceNum = invoice;
        let backUrl = self.$store.state.config.stripeBackUrl + self.invoiceNum;
        let backUrlEnc = encodeURIComponent(backUrl);
        let rootApi = self.$store.state.config.apiRoot;
        self.url = rootApi + "/api/pay/stripe?token=" + localStorage.getItem('SSID') 
        + "&order_sn=" + self.invoiceNum
        + "&price=" + self.myProduct.amount
        + "&back_url=" + backUrlEnc;
      })
      .catch(function(error) {
        console.log(error);
      })
    },
    swipeGetPublicKey () {
      let self = this;
      let invoice = self.$store.state.config.invoiceNum
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/config'
      this.axios.get(apiCall, header)
      .then(function(response) {
        let publicKey = response.data.data.public_key;
        self.publishableKey = publicKey;
        //self.swipePay(publicKey);
      })
    },
    swipePay (publicKey) {

    },
    receiptUpload (f) {
      let self = this;
      let apiCall = f.action
      let formData = new FormData();
      formData.append('image', f.file);
      let header = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
      };
      this.axios.post(apiCall, formData, header)
      .then(function(response) {
        if (response.data.code === 0) {
          self.receiptList.push(response.data.data.url);
          self.alertMessage = self.$t('login.uploadImageSuccess')
          self.showAlertDialog = true;     
        } else {
          self.receiptList.push(response.data.data.url);
          self.alertMessage = self.$t('login.uploadImageFail')
          self.showAlertDialog = true;     
        };
      })
      .catch(function(error) {
				self.$notify.error({
          title: '上傳失敗',
          message: error,
          showClose : true
        });	
      })
    },
    goOfflinePayment () {
      let self = this;
      self.offlinePaymentState = true;
      if (self.offlinePayType != 'bank_transfer' && self.offlinePayType != 'cheque_mailing') {
        self.alertMessage = self.$t('cart.missingOfflinePayType')
        self.showAlertDialog = true;
        self.offlinePaymentState = false;
        return
      }
      let offlineType = self.offlinePayType;
      let formData = new FormData();
      formData = {
        type: offlineType,
        order_sn: self.invoiceNum,
      };
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/offline';
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      };
      this.axios.post(apiCall, formData, header)
      .then(function(response) {
        let code = response.data.code;
        switch(code) {
          case 0:
            self.showSuccessDialog = true;
            self.offlinePaymentState = false;
            break
          default:
            self.offlinePaymentState = false;
            break
        }
      })
      .catch(function(error) {
        console.log(error)
      })
    },
    offlinePayment2 () {
      let self = this;
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      };
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/offline';
      let formData = new FormData();
      switch(self.offlinePayType) {
        case 0: // BANK TRANSFER PAYMENT
          formData = {
            type: "bank_transfer",
            order_sn: self.invoiceNum,
            voucher_image: self.receiptList,
          }
          this.axios.post(apiCall, formData, header)
          .then(function(response) {
            let code = response.data.code;
            switch(code) {
              case 0:
                self.showSuccessDialog = true
                break
              default:
                break
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          break
        case 1: // CHEQUE MAILING
          formData = {
            type: "cheque_mailing",
            order_sn: self.invoiceNum,
          }
          this.axios.post(apiCall, formData, header)
          .then(function(response) {
            let code = response.data.code;
            switch(code) {
              case 0:
                self.showSuccessDialog = true
                break
              default:
                break
            }
          })
          .catch(function(error) {
            console.log(error)
          })
          break
          
      }
    },
    payType(type) {
      if(type == 2) {
        this.offlinePayType = 'bank_transfer'
        this.offlinePaymentLabel = '選擇轉數快/銀行入數/轉帳 FPS / Bank in deposit/transfer'
      } else {
        this.offlinePayType = ''
        this.offlinePaymentLabel = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/payment';
</style>

<style>
button.btn.btn-primary.btn-stripe-checkout {
  width: 200px;
  height: 50px;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0 0 20px #333333;
  background-color: white;
}
.el-select {
  width: 350px!important;
}
.hk-alert {
height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
.btn-stripe-checkout {
  background-color: #3f9b3f!important;
  color: white!important;
}
</style>