<template>
  <div class="hk-cart">
    <div class="hk-table">
      <!--- // address Section --->
      <div class="hk-address">
        <ul>
          <li v-for="item in addressDisplay" :key="item.id" class="hk-address__item">
            <div class="hk-address__card" :class="{'hk-address__card_checked': item.checked}">
              <div class="hk-address__header">
                <p>{{ item.name }}</p>
                <el-button
                  style="z-index: 998;"
                  @click="editAddress(item.id)"
                >{{ $t('common.edit') }}</el-button>
                <el-button
                  style="z-index: 997;"
                  @click="deleteAddress(item.id)"
                >{{ $t('common.delete') }}</el-button>
              </div>
              <div class="hk-address__body">
                <p>{{ item.address }}</p>
                <p>{{ item.mobile }}</p>
              </div>
              <el-checkbox
                style="float: right;"
                :value="item.checked"
                @change="handleSelectAddress(item)"
              />
            </div>
            <div class="hk-address__amount">
              <a
                v-if="item.selectType === 0"
                @click="handleSelect(item.id)"
              >{{ $t('cart.selectProduct') }}</a>
              <template v-else>
                <p>{{ $t('cart.chosen') }}：{{ item.selectType === 1 ? $t('cart.partialProduct') : '' }}</p>
                <ul>
                  <li
                    v-for="product in item.productDisplay"
                    :key="product.product_id"
                  >{{ product.product_name }} X {{ product.product_quantity }}</li>
                </ul>
                <el-button>
                  <a @click="handleSelect(item.id)">{{ $t('common.edit') }}</a>
                  <i class="iconfont icon-edit" />
                </el-button>
              </template>
            </div>
          </li>
        </ul>
        <el-button @click="handleAddressAdd">
          <i class="el-icon-plus" />
          {{ $t('cart.addAddress') }}
        </el-button>
      </div>
      <!--- // delivery section --->
      <div class="hk-transport">
        <el-radio-group v-model="transportType" v-on:change="getFreightFee">
          <el-radio :label="1">{{ $t('cart.normalDelivery') }}</el-radio>
          <el-radio v-show="!freeTransportFee" :label="2">{{ $t('cart.sameDayDelivery') }}</el-radio>
          <!-- <el-radio v-show="!freeTransportFee" :label="3">{{ $t('cart.urgentDelivery') }}</el-radio> -->
          <el-radio :label="4">{{ $t('cart.pickUp') }}</el-radio>
        </el-radio-group>
        <p v-if="transportType === 2">
          <i />
          {{ $t('cart.sameDayDesc', [checkoutCalculation.serviceFee]) }}
        </p>
        <p v-if="transportType === 3">
          <i />
          {{ $t('cart.urgentDesc', [checkoutCalculation.serviceFee]) }}
        </p>
        <p v-if="transportType === 4">
          <i />
          {{ checkoutCalculation.pickupDescription }}
        </p>
        <!---
        <div v-else-if="transportType === 4">
          <p>
            <i />
            {{ $t('cart.pickUpDesc') }}:
          </p>
          <el-date-picker
            v-model="pickupDate"
            type="date"
            format="yyyy/MM/dd"
            value-format="yyyy-MM-dd"
            :placeholder="$t('cart.selectDate')"
            :picker-options="pickerOptions"
          />
          <el-select
            class="date-pick"
            v-model="pickupPeriod"
            :placeholder="$t('cart.selectPeriod')"
            :no-data-text="$t('common.noData')" 
          >
            <el-option
              v-for="item in pickupPeriods"
              :key="item.id"
              :label="item.period"
              :value="item.id"
            />
          </el-select>
        </div>
        --->
      </div>
    </div>
    <!--- // checkout section --->
    <div class="hk-total">
      <div class="hk-total__table hk-total__table_top">
        <dl class="hk-total__header">
          <dt>{{ $t('cart.expensesSubtotal') }}</dt>
          <dd
            class="hk-total__subtotal"
          >HK$ {{ ( checkoutCalculation.newSubtotal + checkoutCalculation.refund ).toFixed(2) }}</dd>
        </dl>
        <!-- <dl class="hk-total__present">
          <dt>{{ $t('cart.rebate') }}</dt>
          <dd>- HK$ {{ checkoutCalculation.refund.toFixed(2) }}</dd>
        </dl> -->
        <dl>
          <dt v-if="checkoutCalculation.deliveryType">{{ checkoutCalculation.deliveryType }}</dt>
          <dt v-if="!checkoutCalculation.deliveryType">{{ $t('cart.deliveryFee') }}</dt>
          <dd>HK$ {{ ( checkoutCalculation.serviceFee + checkoutCalculation.freightFee ).toFixed(2) }}</dd>
        </dl>
      </div>
      <dl class="hk-total__amount">
        <dt>{{ $t('cart.total') }}</dt>
        <dd>
          HK$ {{
          (checkoutCalculation.newSubtotal +
          checkoutCalculation.freightFee +
          checkoutCalculation.serviceFee).toFixed(2) }}
        </dd>
      </dl>
      <div class="hk-total__table">
        <el-table :data="productList_display">
          <el-table-column :label="$t('cart.product')" prop="name" class-name="hk-table_title" />
          <el-table-column :label="$t('cart.quantity')" class-name="hk-table_title">
            <template slot-scope="{ row }">
              <span>{{ row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-if="giftList.length" :data="giftList">
          <el-table-column :label="$t('cart.freeProduct')" class-name="hk-table_title">
            <template slot-scope="{ row }">
              <div v-if="row.selected == 'Y' && row.num != 0">{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="{ row }">
              <span v-if="row.selected == 'Y' && row.num != 0">{{ row.num }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="hk-total__button">
        <el-button @click="prev">{{ $t('common.prev') }}</el-button>
        <el-button type="primary" @click="checkout">{{ $t('common.confirmInvoice') }}</el-button>
      </div>
    </div>

    <el-dialog
      class="hk-dialog hk-address-dialog"
      :title="$t('cart.addAddress')"
      :visible.sync="showAddressDialog"
    >
      <el-form label-position="right" :model="addressData" ref="addressData">
        <el-form-item
          :label="$t('common.area')"
          prop="mailAddress"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-select v-model="addressData.mailAddress" :placeholder="$t('common.areaDesc')">
            <el-option
              v-for="item in mailAddressOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          prop="mailAddressDetail"
          :label="$t('common.deliveryAddress')"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input
            v-model="addressData.mailAddressDetail"
            :placeholder="$t('common.deliveryAddressDesc')"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.contact')"
          prop="name"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.name" :placeholder="$t('common.contactDesc')" />
        </el-form-item>
        <el-form-item
          :label="$t('common.phone')"
          prop="phone"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.phone" maxlength="8" :placeholder="$t('common.fillIn')" />
        </el-form-item>
        <el-form-item :label="$t('common.email')">
          <el-input v-model="addressData.emial" :placeholder="$t('common.fillIn')" />
        </el-form-item>
      </el-form>
      <div class="hk-dialog__button">
        <el-button @click="handleAddressCancel">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="handleAddressSave">{{ $t('cart.saveAddress') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      id="updateAddress"
      class="hk-dialog hk-address-dialog"
      :title="$t('cart.addAddress')"
      :visible.sync="showUpdateAddressDialog"
    >
      <el-form label-position="right" :model="addressData" ref="addressData">
        <el-form-item :label="$t('common.area')" required>
          <el-select v-model="addressData.mailAddress" :placeholder="$t('common.areaDesc')">
            <el-option
              v-for="item in mailAddressOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.deliveryAddress')"
          prop="mailAddressDetail"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input
            v-model="addressData.mailAddressDetail"
            :placeholder="$t('common.deliveryAddressDesc')"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
        <el-form-item
          class="hk-form-item_long"
          :label="$t('common.contact')"
          prop="name"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.name" :placeholder="$t('common.contactDesc')" />
        </el-form-item>
        <el-form-item
          :label="$t('common.phone')"
          prop="phone"
          :rules="[
			      { required: true, message: $t('forms.required'), trigger: 'blur' },
			    ]"
        >
          <el-input v-model="addressData.phone" maxlength="8" :placeholder="$t('common.fillIn')" />
        </el-form-item>
        <el-form-item :label="$t('common.email')">
          <el-input v-model="addressData.emial" :placeholder="$t('common.fillIn')" />
        </el-form-item>
      </el-form>
      <div class="hk-dialog__button">
        <el-button @click="handleAddressCancel">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="handleEditSave">{{ $t('cart.saveAddress') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="hk-dialog hk-select-dialog"
      :title="$t('cart.selectProduct')"
      :visible.sync="showSelectDialog"
    >
      <el-radio-group v-model="selectData.type" v-on:change="deliverySplitToggle">
        <el-radio :label="2">{{ $t('cart.allProduct') }}</el-radio>
        <el-radio :label="1">{{ $t('cart.partialProduct') }}</el-radio>
      </el-radio-group>
      <ul>
        <li v-for="selectProduct in selectData.products" :key="selectProduct.product_id">
          <p>{{ selectProduct.product_name }}</p>
          <el-input-number
            v-model="selectProduct.product_quantity"
            :max="selectProduct.product_remainQuantity"
            :min="0"
            controls-position="right"
            :placeholder="$t('cart.fillQuantity')"
          />
          <a
            style="z-index: 999;"
            @click="handleSelectAll(selectProduct)"
          >{{ $t('cart.all') }}({{ selectProduct.product_remainQuantity }})</a>
        </li>
      </ul>
      <el-button type="primary" @click="handleSelectConfirm">{{ $t('common.confirm') }}</el-button>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="errorDialogue">
      <div>
        <p>{{ errorMsg }}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="errorDialogue = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog class="hk-alert" :visible.sync="showUnselectProduct">
      <div>
        <p style="text-align: center;">{{ $t('cart.unselectProduct')}}</p>
      </div>
      <div class="hk-alert__button">
        <el-button type="primary" @click="showUnselectProduct = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'HkCartAddress',
  data() {
    this.getAreaOptions()
    this.getProductList()
    this.getPickupDetail();
    let pickupDateMin = this.$store.state.config.minDate - 100000000
    let pickupDateMax = this.$store.state.config.maxDate
    let pickupSlot = this.$store.state.config.pickupSlot
    if (!pickupSlot) {
      pickupSlot = [
        {
          date: '2000-01-01',
          dateUnix: 1597363200000,
          end_hour: 20,
          id: 0,
          period: '12:00:00 -20:00:00',
          start_hour: 12,
        },
      ]
    }
    let pickDate = []
    pickupSlot.forEach(function (item) {
      let time = new Date(item.date)
      let yyyy = time.getFullYear()
      let mm =
        time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1 // getMonth() is zero-based
      let dd = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
      let timer = ''.concat(yyyy).concat(mm).concat(dd)
      pickDate.push(timer)
    })
    return {
      address: {},
      addressDisplay: [],
      subtotal: 0,
      present: 0,
      freight: 0,
      express: 0,
      total: 0,
      amountData: [
        {
          name: 'A2',
          value: 10,
        },
        {
          name: '19pcs/set',
          value: 20,
        },
      ],
      presentData: [
        {
          name: 'MDP/Q7',
          value: 5,
        },
        {
          name: 'W2',
          value: 5,
        },
      ], // real address list
      transportType: 1,
      pickupDate: '',
      pickupPeriod: '',
      pickupSlot: pickupSlot,
      tempAddressId: 0,
      productList: [], // real product list
      productList_display: [],
      giftList: [], // real gift list
      showAddressDialog: false,
      addressData: {
        mailAddress: '',
        mailAddressDetail: '',
        name: '',
        phone: '',
        emial: '',
      },
      totalItemNumber: 0,
      checkoutCalculation: {
        oriSubtotal: 0,
        newSubtotal: 0,
        refund: 0,
        freightFee: 0,
        serviceFee: 0,
        deliveryType: '',
      }, // for item related to checkout calculation
      showSelectDialog: false,
      selectData: {
        type: 1,
        products: [],
      },
      mailAddressOptions: [],
      addressProduct: [],
      pickerOptions: {
        disabledDate(time) {
          let yyyy = time.getFullYear()
          let mm =
            time.getMonth() < 9
              ? '0' + (time.getMonth() + 1)
              : time.getMonth() + 1 // getMonth() is zero-based
          let dd = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
          let timer = ''.concat(yyyy).concat(mm).concat(dd)
          return !pickDate.includes(timer)
        },
      },
      pickupDateMin: 0,
      pickupDateMax: 0,
      showUnselectProduct: false,
      wrongAddressFormat: false,
      wrongAddressMsg: '',
      showUpdateAddressDialog: false,
      errorMsg: '',
      errorDialogue: false,
      freeTransport: 'N',
    }
  },
  computed: {
    pickupPeriods: function () {
      let dateChoice = this.pickupDate
      let timeChoice = this.pickupSlot.filter((item) => item.date == dateChoice)
      return timeChoice
    },
    freeTransportFee() {
      let self = this
      let status = false
      if (self.freeTransport == 'Y') {
        status = true
      }
      return status
    },
  },
  methods: {
    updateRemainingProducts() {
      let self = this
      self.selectData.products.forEach(function (item) {
        let max = item.product_maxQuantity
        let remain = item.product_maxQuantity
        Object.keys(self.address).forEach(function (element) {
          let num = 0
          num =
            self.address[element].productDisplay[item.product_id]
              .product_quantity
          remain = remain - num
          item.product_remainQuantity = remain
          //item.product_remainQuantity = item.product_remainQuantity - num
        })
      })
    },
    handleAddressAdd() {
      this.showAddressDialog = true
    },
    handleAddressCancel() {
      this.showAddressDialog = false
      this.showUpdateAddressDialog = false
    },
    handleSelect(id) {
      let self = this
      let product = self.addressDisplay.filter((item) => item.id == id)[0]
        .productDisplay
      Object.keys(product).forEach(function (item) {
        product[item].product_quantity = 0
      })
      self.updateRemainingProducts()
      self.tempAddressId = id
      self.showSelectDialog = true
    },
    handleSelectAll(item) {
      item.product_quantity = item.product_remainQuantity
    },
    deliverySplitToggle() {
      let self = this
      if (self.selectData.type === 2) {
        self.selectData.products.forEach(function (item) {
          item.product_quantity = item.product_maxQuantity
        })
      } else if (self.selectData.type === 1) {
        self.selectData.products.forEach(function (item) {
          item.product_quantity = 0
        })
      }
    },
    handleSelectConfirm() {
      let self = this
      self.selectData.products.forEach(function (item) {
        let id = item.product_id
        self.address[self.tempAddressId]['productDisplay'][
          id
        ].product_quantity = item.product_quantity
        self.address[self.tempAddressId].checked = true
        self.address[self.tempAddressId].selectType = self.selectData.type
        item.product_quantity = 0
        self.selectData.type = 1
      })

      self.showSelectDialog = false
      self.updateRemainingProducts()
      self.getFreightFee()
    },
    handleSelectAddress(data) {
      if (data.checked == true) {
        data.checked = false
        data.selectType = 0
        Object.keys(data.productDisplay).forEach(function (item) {
          data.productDisplay[item].product_quantity = 0
        })
      } else if (data.checked == false) {
        data.checked = true
        data.selectType = 1
      }
      this.getFreightFee()
    },
    next() {
      this.$emit('next')
    },
    prev() {
      this.$emit('prev')
    },
    getAreaOptions() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/address/areas?lang=' + self.$store.state.config.language
      this.axios
        .get(apiCall, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          self.mailAddressOptions = response.data.data
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAddress(productData) {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let lang = this.$store.state.config.language
      let apiCall = rootApi + '/api/address/collection?lang=' + lang
      this.axios
        .get(apiCall, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          self.address = {}

          let data1 = self.addressHandler(response.data.data)
          data1.forEach(function (item) {
            self.address[item.id] = item
          })

          self.addressDisplay = []

          Object.keys(self.address).forEach(function (item) {
            self.address[item]['productDisplay'] = {}
            productData.forEach(function (product) {
              let data2 = {
                product_id: product.product_id,
                product_name: product.name,
                product_quantity: 0,
                product_maxQuantity: product.num,
              }
              self.address[item]['productDisplay'][product.product_id] = data2
            })
            self.addressDisplay.push(self.address[item])
          })

          self.getFreightFee()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addressHandler(data) {
      data.forEach(function (address) {
        if (address.is_default == 'Y') {
          address.checked = true
          address.selectType = 2
        } else {
          address.checked = false
          address.selectType = 0
        }
        address.products = Array()
      })
      return data
    },
    handleAddressSave() {
      let self = this
      let validate = false
      this.$refs['addressData'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      var formData = new FormData()
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/create?'
      formData = {
        area_id: self.addressData.mailAddress,
        details: self.addressData.mailAddressDetail,
        name: self.addressData.name,
        mobile: self.addressData.phone,
        email: self.addressData.emial,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showAddressDialog = false
              self.getProductList()
              break
            case 400:
              // 參數格式錯誤
              self.errorMsg = self.$t('forms.emailOrPhoneTypeError')
              self.errorDialogue = true
              break
            case 404:
              self.errorMsg = response.data.msg
              self.errorDialogue = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    deleteAddress(id) {
      let self = this
      let addressId = id
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/del?'
      let formData = new FormData()
      formData = {
        lang: this.$store.state.config.language,
        id: addressId,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              var i
              for (i = 0; i < self.addressDisplay.length; i++) {
                if (self.addressDisplay[i].id == addressId) {
                  self.addressDisplay.splice(i, 1)
                }
              }
              self.errorMsg = self.$t('account.deleteSuccess')
              self.errorDialogue = true
              break
            case 2026:
              self.errorMsg = self.$t('account.cannotDeleteRegAddress')
              self.errorDialogue = true
              break
          }
          self.getTableData()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    editAddress(id) {
      let self = this
      let addressId = id
      self.showUpdateAddressDialog = true
      // DISPLAYING EDITING DATA ON EDIT FORM
      let editingEntry = self.addressDisplay.filter(
        (data) => data.id === addressId
      )[0]
      self.addressData = {
        mailAddress: editingEntry['area_id'],
        mailAddressDetail: editingEntry['details'],
        name: editingEntry['name'],
        phone: editingEntry['mobile'],
        emial: editingEntry['email'],
        id: addressId,
      }
    },
    handleEditSave() {
      // api call
      let self = this
      let validate = false
      this.$refs['addressData'].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/update?'
      let formData = new FormData()
      formData = {
        lang: this.$store.state.config.language,
        id: self.addressData.id,
        area_id: self.addressData.mailAddress,
        details: self.addressData.mailAddressDetail,
        name: self.addressData.name,
        mobile: self.addressData.phone,
        email: self.addressData.emial,
      }
      this.axios
        .post(apiCall, formData, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          //self.getTableData();
          let code = response.data.code
          switch (code) {
            case 0:
              self.showUpdateAddressDialog = false
              self.getProductList()
              self.initAddressData()
              break
            case 402:
              self.alertMessage = self.$t('common.underReview')
              self.reviewDialogShow = true
              self.showUpdateAddressDialog = false
              break
            case 400:
              // 參數格式錯誤
              self.errorMsg = self.$t('forms.emailOrPhoneTypeError')
              self.errorDialogue = true
              break
            case 404:
              self.errorMsg = response.data.msg
              self.errorDialogue = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getTableData() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/address/collection?'
      let formData = new FormData()
      formData = {
        lang: this.$store.state.config.language,
      }
      this.axios
        .get(
          apiCall,
          {
            headers: {
              token: localStorage.getItem('SSID'),
            },
          },
          formData
        )
        .then(function (response) {
          self.tableData = response.data.data
          self.total = self.tableData.length
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getProductList() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/products?lang='
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.freeTransport =
            response.data.data.preferential.free_transpotation

          self.productList_display = response.data.data.goods.filter(
            (item) => item.selected == 'Y'
          )
          self.productList = []
          self.productList_display.forEach((item) => {
            self.productList.push(JSON.parse(JSON.stringify(item)))
          })
          self.totalItemNumber = 0
          self.productList.forEach(function (item) {
            self.totalItemNumber += item.num
          })
          self.checkoutCalculation.oriSubtotal = response.data.data.origin_sum
          self.checkoutCalculation.newSubtotal = response.data.data.sum
          self.checkoutCalculation.refund = response.data.data.use_back_money
          self.giftList = response.data.data.preferential.can_select_gifts
          self.giftList.forEach(function (item) {
            if (item.selected == 'Y') {
              self.totalItemNumber += item.num
            }
          })

          self.giftList.forEach((item) => {
            if (item.selected == 'Y') {
              let duplicateCheck = false
              self.productList.forEach((data) => {
                if(data.product_id == item.id) {
                  data.num += item.num
                  duplicateCheck = true
                }
              })

              if(!duplicateCheck) {
                let newItem = {
                  product_id: item.id,
                  name: item.name,
                  num: item.num,
                  selected: 'Y',
                }
                self.productList.push(newItem)
              }
            }
          })

          self.selectDataGetter(self.productList)
          self.getAddress(
            self.productList.filter((item) => item.selected == 'Y')
          )
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    selectDataGetter(data) {
      let self = this
      self.selectData.products = []
      data.forEach(function (item) {
        if (item.selected == 'Y') {
          let itemData = {}
          itemData = {
            product_name: item.name,
            product_quantity: 0,
            product_id: item.product_id,
            product_maxQuantity: item.num,
            product_remainQuantity: item.num,
          }
          self.selectData.products.push(itemData)
        }
      })
    },
    getFreightFee() {
      let self = this
      let delivery_type_id = self.transportType
      if (self.freeTransportFee) {
        self.checkoutCalculation.freightFee = 0
        self.checkoutCalculation.serviceFee = 0
        return
      }
      let total_num = self.totalItemNumber
      let address_id = ''
      //let selected_address = self.address.filter(item => item.checked == true)
      let selected_address = {}
      let address_id_length = 0
      let addressInfo = {}
      
      Object.keys(self.address).forEach(function (item) {
        let obj = self.address[item]
        if (obj.checked == true) {
          Object.keys(obj.productDisplay).forEach((index) => {
            let value = obj.productDisplay[index]
            if(value.product_quantity !== 0) {
              if(addressInfo[obj.id]) {
                addressInfo[obj.id] += value.product_quantity
              } else {
                addressInfo[obj.id] = value.product_quantity
              }
            }
          })
        }
      })

      addressInfo = JSON.stringify(addressInfo)
      
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = ''
      if (delivery_type_id == 4) {
        apiCall =
          rootApi +
          '/api/shopping/calculateExtraFee?' +
          '&lang=' +
          this.$store.state.config.language +
          '&delivery_type_id=' +
          delivery_type_id +
          '&total_num=' +
          total_num
      } else {
        apiCall =
          rootApi +
          '/api/shopping/calculateExtraFee?' +
          'lang=' +
          this.$store.state.config.language +
          '&address_info=' +
          addressInfo +
          '&delivery_type_id=' +
          delivery_type_id +
          '&total_num=' +
          total_num
      }
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          let res = response.data.data
          let code = response.data.code
          switch (code) {
            case 0:
              self.checkoutCalculation.freightFee = res.freight
              self.checkoutCalculation.serviceFee = res.service.service_fee
              self.checkoutCalculation.deliveryType = res.service.service_name
              // self.checkoutCalculation.pickupDescription = res.des
              break
            case 404:
              self.checkoutCalculation.freightFee = 0
              self.checkoutCalculation.serviceFee = 0
              self.checkoutCalculation.deliveryType = ''
              // self.checkoutCalculation.pickupDescription = ''
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getPickupDetail() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/pick_up_time/list?lang='+this.$store.state.config.language
      await this.axios
        .get(apiCall)
        .then(function (response) {
          let arr = response.data.data

          self.checkoutCalculation.pickupDescription = arr[0].content
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    checkout() {
      let self = this
      let addressProduct = self.createAddressProduct()
      let formData = new FormData()

      /// CREATE GOODS LIST
      let goods = []
      self.productList_display.forEach(function (item) {
        let tempObj1 = {
          product_id: Number,
          num: Number,
        }
        tempObj1.product_id = item.product_id
        tempObj1.num = item.num
        goods.push(tempObj1)
      })
      if (self.transportType != 4) {
        self.productList.forEach(function (product) {
          let productNum = 0
          let productId = 0
          productNum = product.num
          productId = product.product_id
          addressProduct.forEach(function (address) {
            if (address.product_id == productId) {
              productNum -= address.num
            }
          })
          if (productNum > 0) {
            self.showUnselectProduct = true
            return
          }
        })
      }
      if (self.showUnselectProduct) {
        return
      }

      /// CREATE GIFTS LIST
      let gifts = []
      self.giftList.forEach(function (item) {
        let tempObj2 = {
          product_id: Number,
          num: Number,
        }
        tempObj2.product_id = item.id
        tempObj2.num = item.num
        if (item.selected == 'Y') {
          gifts.push(tempObj2)
        }
      })
      if (self.transportType === 4) {
        formData = {
          goods: JSON.stringify(goods),
          gifts: JSON.stringify(gifts),
          //address_product: JSON.stringify(addressProduct),
          delivery_type_id: self.transportType,
          pick_up_id: self.pickupPeriod,
        }
      } else {
        formData = {
          goods: JSON.stringify(goods),
          gifts: JSON.stringify(gifts),
          address_product: JSON.stringify(addressProduct),
          delivery_type_id: self.transportType,
        }
      }
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
          contentType: 'application/json',
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/order/create?lang=zh'
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              let invoiceNum = response.data.data.sn
              self.$store.commit('saveInvoiceNum', invoiceNum)
              self.updateCart()
              self.next()
              break
            case 2022:
              self.$notify.warning({
                title: 'WRONG',
                message: response.data.msg,
              })
              break
            default:
              self.$notify.warning({
                title: 'WRONG',
                message: 'WRONG',
              })
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    createAddressProduct() {
      ////
      let self = this
      let addressProduct = []
      Object.keys(self.address).forEach(function (item) {
        Object.keys(self.address[item]['productDisplay']).forEach(function (
          element
        ) {
          let data = {
            address_id: 0,
            product_id: 0,
            num: 0,
          }
          if (
            self.address[item]['productDisplay'][element]['product_quantity'] >
              0 &&
            self.address[item]['productDisplay'][element]['product_quantity'] >
              0 !=
              NaN
          ) {
            data.address_id = item
            data.product_id =
              self.address[item]['productDisplay'][element]['product_id']
            data.num =
              self.address[item]['productDisplay'][element]['product_quantity']
            addressProduct.push(data)
          }
        })
      })
      return addressProduct
    },
    updateCart() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios.get(apiCall, header).then(function (response) {
        let num = response.data.data.product_num
        self.$store.commit('updateCartNum', num)
      })
    },
    initAddressData() {
      this.addressData = {
        mailAddress: 1,
        mailAddressDetail: '',
        name: '',
        phone: '',
        emial: '',
        id: Number,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/address';
</style>
<style>
.el-select {
  width: 6rem;
}
.date-pick .el-input__inner {
  width: 100% !important;
}
.hk-alert {
  height: 600px;
}
.hk-alert .hk-alert__button {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hk-alert .el-dialog__body {
  padding: 1rem;
}
</style>
